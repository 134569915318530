import * as _ from 'lodash'
import { parseInstance } from '../../utils/utils'
import * as axios from 'axios'
import { CustomField, CustomFieldResponse } from '../../constants/field-types'
import { FORMS_APP_DEF_ID } from '../../constants'
import { PremiumRestriction as DomainPremiumRestriction } from '../../types/domain-types'
import { PremiumRestriction, Feature } from '../../constants/premium'
import { FormPlugin } from '../../constants/plugins'
import { ASCEND_PLAN, MAP_PRODUCT_ID_TO_ASCEND_PLAN } from './constants/ascend'
import { WixContactsWebapp } from '@wix/ambassador-wix-contacts-webapp/http'

export enum Method {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
}

export const FEATURES = {
  CUSTOM_STEPS: 'custom_steps',
  SUBMISSIONS_PER_MONTH: 'submissions_per_month',
  CUSTOM_FIELDS: 'custom_fields',
  CUSTOMIZABLE_FORMS: 'customizable_forms',
  ACCEPT_PAYMENTS: 'accept_payments_on_form',
  UPLOAD_FIELD: 'file_upload_field',
  DOWNLOAD_FILE: 'file_downloads',
  SIGNATURE_FIELD: 'signature_field',
}

const ASCEND_PRODUCT_ID = '73988963-5f5f-4f61-b6a1-fd004df31b00'

const hasQuery = (uri: string) => _.includes(uri, '?')

export const FORMS_URL = 'https://editor.wix.com/_api/wix-form-builder-web'
export const PLATFORMIZED_FORMS_URL = `${FORMS_URL}/v1`
const PLATFORMIZED_PAYMENTS_URL = 'https://editor.wix.com/_api/payment-services-web/payments/v2'
export const CONTACTS_URL = 'https://editor.wix.com/_api/wix-contacts-webapp/v1'

export const FEATURES_MANAGER_URL = 'https://editor.wix.com/_api/premium-features-manager/v1'
export const PREMIUM_STORE_URL = 'https://editor.wix.com/_api/store/v1'

const PREDEFINED_LABELS = ['contacts-contacted_me', 'contacts-customers']

//TODO: [PERFORMANCE] Make all get requests cached

const getService = appInstance => ({
  contacts: () => {
    const contactsServices = WixContactsWebapp('/wix-contacts-webapp')
    return {
      labels: contactsServices.ContactsLabelsService()({ Authorization: appInstance }),
      schema: contactsServices.ContactsSchemaService()({ Authorization: appInstance }),
    }
  },
})

export interface ContactsLabel {
  id: string
  name: string
}

export default class RemoteApi {
  private boundEditorSDK
  private experiments
  private ravenInstance

  constructor({ boundEditorSDK, experiments, ravenInstance }) {
    this.boundEditorSDK = boundEditorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
  }

  public getEmailsById = async (emailIds = []) =>
  this._platformizedRequest({
    url: PLATFORMIZED_FORMS_URL,
    endpoint: 'emails/get',
    method: Method.POST,
    data: { emailIds: _.compact(emailIds) },
  }).then(data => {
    // TODO: This implementation supports the old RPC api, when we will touch this again please refactor to be generic
    // Once we will support multi emails in form settings please change it to be more generic (remove the mapping and handle the emails array in forms settings panel)
    const emails = data.emails
    const firstEmailId = _.get(emailIds, '[0]')
    const secondEmailId = _.get(emailIds, '[1]')
    const firstEmail = _.find(emails, { emailId: firstEmailId })
    const secondEmail = _.find(emails, { emailId: secondEmailId })

    return {
      email: firstEmail
        ? firstEmail
        : {
            emailId: firstEmailId,
            email: '',
          },
      secondEmail: secondEmail
        ? secondEmail
        : {
            emailId: secondEmailId,
            email: '',
          },
    }
  })

  public insertEmail = async email =>
    this._platformizedRequest({
      url: PLATFORMIZED_FORMS_URL,
      endpoint: 'emails/add',
      method: Method.POST,
      data: { email },
    })

  public createTag = async tagName => {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.create({ details: { name: tagName } })
      .then(res => res.label)
  }

  public updateTag = async (tagId, newName) => {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.update({ id: tagId, details: { name: newName } })
      .then(res => res.label)
  }

  public getLabels = async (): Promise<ContactsLabel[]> => {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.list({})
      .then(data => {
        const filteredLabels = _.filter(
          data.labels,
          label => label.type === 'USER' || _.includes(PREDEFINED_LABELS, label.id)
        )
        return _.map(filteredLabels, label => ({
          id: label.id,
          name: label.details.name,
        }))
      })
  }

  public getCustomFields = async (): Promise<CustomFieldResponse[]> => {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.listFields({})
      .then(({ fields }) =>
        fields
          .filter(field => field.fieldType === 'USER_DEFINED')
          .map<CustomFieldResponse>(field => ({
            id: field.id,
            name: field.details.name,
            // TODO: use DataType instead of customFields
            fieldType: field.details.dataType as any,
          }))
      )
      .catch(() => [])
  }

  public createCustomField = async (field: CustomField) => {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.createField({ details: { name: field.name, dataType: field.fieldType as any } })
      .then(res => res.field)
  }

  public updateCustomFieldName = async ({
    id,
    newName,
  }: {
    id: string
    newName: string
  }): Promise<void> => {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    await getService(appInstance)
      .contacts()
      .schema.updateField({
        id,
        details: {
          name: newName,
        },
      })
  }

  public publishSite = data =>
    this._platformizedRequest({
      url: PLATFORMIZED_FORMS_URL,
      endpoint: 'publish-site',
      method: Method.POST,
      data,
    })

  public editDraft = form =>
    this._platformizedRequest({
      url: PLATFORMIZED_FORMS_URL,
      endpoint: 'edit-draft',
      method: Method.POST,
      data: { form: form },
    }).catch(() => null)

  public async isTopPremium() {
    const { isTopPremium } = await this.getCurrentAscendPlan()
    return isTopPremium
  }

  public getPremiumRestrictions = async (): Promise<{
    restrictions: PremiumRestriction
    currentAscendPlan: ASCEND_PLAN
  }> => {
    const { restrictions: oldRestrictions } = await this._getOldRestrictions()
    const { isTopPremium, currentAscendPlan } = await this.getCurrentAscendPlan()
    const mergedRestrictions = await this._mergeFeaturesWithRestrictions(oldRestrictions, {
      isTopPremium,
    })

    return { restrictions: mergedRestrictions, currentAscendPlan }
  }

  private async _mergeFeaturesWithRestrictions(restrictions: PremiumRestriction, { isTopPremium }) {
    const mergedRestrictions: PremiumRestriction = _.merge({}, restrictions)

    if (isTopPremium) {
      mergedRestrictions.isTopPremium = isTopPremium
    }

    const features = await this._getFeatures()
    const isEligibleFeature = (feature: Feature, name) =>
      feature.uniqueName === name && feature.id !== 'DEFAULT'

    const convertRestriction = (limit, threshold) =>
      limit === -1 ? { limit, threshold: null } : { limit, threshold }

    _.forEach(features, feature => {
      const limit = _.get(feature, 'quotaFeature.limit', -1)

      if (isEligibleFeature(feature, FEATURES.CUSTOM_STEPS)) {
        mergedRestrictions.steps = convertRestriction(limit, feature.quotaFeature.limit - 1)
      }

      if (isEligibleFeature(feature, FEATURES.SUBMISSIONS_PER_MONTH)) {
        mergedRestrictions.submissions = convertRestriction(limit, feature.quotaFeature.limit * 0.7)
      }

      if (isEligibleFeature(feature, FEATURES.CUSTOM_FIELDS)) {
        mergedRestrictions.fields = convertRestriction(limit, feature.quotaFeature.limit * 0.7)
      }

      if (isEligibleFeature(feature, FEATURES.CUSTOMIZABLE_FORMS)) {
        mergedRestrictions.forms = convertRestriction(limit, feature.quotaFeature.limit - 2)
      }

      if (isEligibleFeature(feature, FEATURES.ACCEPT_PAYMENTS)) {
        mergedRestrictions.allowedPlugins[FormPlugin.PAYMENT_FORM] = true
      }

      if (isEligibleFeature(feature, FEATURES.UPLOAD_FIELD)) {
        mergedRestrictions.allowedFields.uploadButton = true
        mergedRestrictions.allowedFields.generalUploadButton = true
      }

      if (isEligibleFeature(feature, FEATURES.DOWNLOAD_FILE)) {
        mergedRestrictions.allowedRedirections.downloadFile = true
      }

      if (isEligibleFeature(feature, FEATURES.SIGNATURE_FIELD)) {
        mergedRestrictions.allowedFields.generalSignature = true
      }
    })

    return mergedRestrictions
  }

  public async getCurrentAscendPlan(): Promise<any> {
    const msid = await this.boundEditorSDK.info.getMetaSiteId()
    const endpoint = `offering/${ASCEND_PRODUCT_ID}?msid=${msid}`
    let currentSubscriptionInfo

    try {
      const request = await this._platformizedRequest({
        url: PREMIUM_STORE_URL,
        endpoint,
        method: Method.GET,
      })
      currentSubscriptionInfo = _.get(request, 'currentSubscriptionInfo')
    } catch {
      currentSubscriptionInfo = {}
    }

    const productId = _.get(currentSubscriptionInfo, 'productId')

    const currentPlan = MAP_PRODUCT_ID_TO_ASCEND_PLAN[productId] || ASCEND_PLAN.FREE

    return { currentAscendPlan: currentPlan, isTopPremium: currentPlan === ASCEND_PLAN.UNLIMITED }
  }

  public getConnectedPayments = async () => {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    const instanceId = parseInstance(appInstance).instanceId
    const endpoint = `accounts/${FORMS_APP_DEF_ID}:${instanceId}/payment-methods`

    return this._platformizedRequest({
      url: PLATFORMIZED_PAYMENTS_URL,
      endpoint,
      method: Method.GET,
    })
  }

  private _getOldRestrictions = () =>
    this._platformizedRequest({
      url: PLATFORMIZED_FORMS_URL,
      endpoint: 'premium/restrictions',
      method: Method.GET,
    }).then((restrictions: DomainPremiumRestriction): { restrictions: PremiumRestriction } => {
      const convertRestriction = restriction =>
        _.get(restriction, 'unlimited')
          ? { limit: -1, threshold: null }
          : _.get(restriction, 'limited')
      const allowedFeatures = <any>restrictions.allowedFeatures
      return {
        restrictions: {
          steps: convertRestriction(restrictions.steps),
          forms: convertRestriction(restrictions.forms),
          submissions: convertRestriction(restrictions.submissions),
          fields: convertRestriction(restrictions.fields),
          isTopPremium: restrictions.isTopPremium,
          allowedFields: {
            generalUploadButton: _.includes(allowedFeatures, 'GENERAL_UPLOAD_BUTTON'),
            generalSignature: _.includes(allowedFeatures, 'SIGNATURE_FIELD'),
          },
          allowedRedirections: {
            downloadFile: _.includes(allowedFeatures, 'DOWNLOAD_FILE'),
          },
          allowedPlugins: {
            [FormPlugin.PAYMENT_FORM]: _.includes(allowedFeatures, 'PAYMENT_FORM'),
          },
        },
      }
    })

  private _getFeatures = async (): Promise<Feature[]> => {
    const supportedFeatures = _.chain(FEATURES)
      .values()
      .map(value => `uniqueNames=${value}`)
      .join('&')
      .value()
    const endpoint = `bulk-features?${supportedFeatures}`
    let features

    try {
      const request = await this._platformizedRequest({
        url: FEATURES_MANAGER_URL,
        endpoint,
        method: Method.GET,
      })
      features = _.get(request, 'features')
    } catch {
      features = []
    }

    return features
  }

  private _get = (url, endpoint) => this._request({ url, endpoint, method: Method.GET })
  private _post = (url, endpoint, data) =>
    this._request({ url, endpoint, method: Method.POST, data })

  private async _platformizedRequest({ url, endpoint, method, data = undefined }) {
    try {
      const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())

      if (isTemplate) {
        return Promise.resolve()
      }

      const appInstance = await this.boundEditorSDK.info.getAppInstance()

      const response = await axios.request({
        headers: { Authorization: appInstance, 'X-Wix-Client-Artifact-Id': 'wix-form-builder' },
        method,
        url: `${url}/${endpoint}`,
        data,
      })

      return _.get(response, 'data')
    } catch (err) {
      this.ravenInstance.setExtraContext({ url, endpoint, method, data })
      throw err
    }
  }

  private async _request({ url, endpoint, method, data = undefined }) {
    try {
      const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())
      const appInstance = await this.boundEditorSDK.info.getAppInstance()
      const response = !isTemplate
        ? await axios.request({
            headers: { 'X-Wix-With-Static-Templates': 'true' },
            method,
            url: `${url}/${endpoint}${hasQuery(endpoint) ? '&' : '?'}instance=${appInstance}`,
            data,
          })
        : null

      return _.get(response, 'data')
    } catch (err) {
      this.ravenInstance.setExtraContext({ url, endpoint, method, data })
      throw err
    }
  }
}
